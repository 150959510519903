// @flow strict
import React from 'react';
import styles from './Privacy.module.scss';

const Privacy = () => (
  <span></span>
  // <a className={styles['privacy']} href="/privacy">
  //   Privacy Policy
  // </a>
);

export default Privacy;
