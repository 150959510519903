// @flow strict
import React from 'react';
import { Link } from 'gatsby';
import * as _ from 'lodash';
import styles from './Menu.module.scss';

type Props = {
  menu: {
    label: string,
    path: string
  }[],
  tags: { fieldValue: String, totalCount: number }[]
};

const Menu = ({ menu, tags }: Props) => (
  <nav className={styles['menu']}>
    <ul className={styles['menu__list']}>
      {menu.map(item => (
        <li className={styles['menu__list-item']} key={item.path}>
          <Link
            to={item.path}
            className={styles['menu__list-item-link']}
            activeClassName={styles['menu__list-item-link--active']}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
    {tags && <h4>Topics</h4>}
    <ul className={styles['menu__list-topic']}>
      {tags &&
        tags.map(tag => (
          <li key={tag.fieldValue}>
            <Link
              to={`/tag/${_.kebabCase(tag.fieldValue)}/`}
              className={styles['menu__list-item-link']}
              activeClassName={styles['menu__list-item-link--active']}
              style={{ fontSize: '0.8rem' }}
            >
              {tag.fieldValue} ({tag.totalCount})
            </Link>
          </li>
        ))}
    </ul>
  </nav>
);

export default Menu;
